@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
      font-family: 'helvetica';
      font-display: swap;
      src: url(../src/assets/fonts/helvetica.ttf) format('ttf');
    }
  }